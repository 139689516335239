import Cookies from "js-cookie";

import { OPTANON_CONSENT_COOKIES } from "~/constants/userCookies";
import { getCookieCategories } from "~/utils/onetrust/oneTrustCookieParser";

export const loadCookieBanner = (): void => {
  const bannerElement = document?.getElementById("onetrust-banner-sdk");
  const shouldBannerShowing =
    !bannerElement || bannerElement?.style?.display === "none";

  if (shouldBannerShowing) {
    const otConsentSdk = document.getElementById("onetrust-consent-sdk");
    if (otConsentSdk) {
      otConsentSdk.remove();
    }
    /* @ts-expect-error Due to undefined*/
    OneTrust.Init();
    /* @ts-expect-error Due to undefined*/
    OneTrust.LoadBanner();
  }
};

export const alignCategoryCheckboxWithTerritoryCookie = (): void => {
  const optanonConsent = Cookies.get(OPTANON_CONSENT_COOKIES);
  if (optanonConsent) {
    const cookieCategories = getCookieCategories(optanonConsent);
    const categoryCheckboxes = document
      ?.getElementById("onetrust-consent-sdk")
      ?.getElementsByClassName(
        "category-switch-handler"
      ) as HTMLCollectionOf<HTMLInputElement>;

    if (categoryCheckboxes?.length) {
      for (let i = 0; i < categoryCheckboxes?.length; i++) {
        const optanongroupId = categoryCheckboxes[i]?.dataset?.optanongroupid;

        if (optanongroupId && cookieCategories[optanongroupId]) {
          categoryCheckboxes[i].setAttribute("checked", "checked");
          categoryCheckboxes[i].setAttribute("aria-checked", "true");
          categoryCheckboxes[i].checked = true;
        }
        if (optanongroupId && !cookieCategories[optanongroupId]) {
          categoryCheckboxes[i].removeAttribute("checked");
          categoryCheckboxes[i].setAttribute("aria-checked", "false");
          categoryCheckboxes[i].checked = false;
        }
      }
    }
  }
};
